
import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getrewardstatus, getStakeLicContract } from '../lic/utils'
import useYam from './useYam'
import useBlock from './useBlock'

const RewardStatus = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account }: { account: string } = useWallet()
  const yam = useYam()
  const stakeLicContract = getStakeLicContract(yam)
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    const balance = await getrewardstatus(stakeLicContract, pid, account)
    var status;
    if(balance == true) status = 1
    if(balance == false) status = 0
    console.log("Reward Status:"+ status)
    setBalance(new BigNumber(status))
  }, [account, pid, yam])

  useEffect(() => {
    if (account && yam) {
      fetchBalance()
    }
  }, [account, pid, setBalance, block, yam])
  

  return balance
}

export default RewardStatus
