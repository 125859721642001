import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Link } from 'react-router-dom'

interface ButtonProps {
  children?: React.ReactNode,
  lightBG?:  'c1' | 'c2' | 'c3' | 'c4',
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  size?: 'sm' | 'md' | 'lg',
  text?: string,
  to?: string,
  variant?: 'default' | 'secondary' | 'tertiary'
}

const Button: React.FC<ButtonProps> = ({
  children,
  lightBG,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  variant,
}) => {
  const { color, spacing } = useContext(ThemeContext)

  let buttonColor: string
  let buttonTxtColor: string
  switch (variant) {
    case 'secondary':
      switch(lightBG)
      {
        case 'c1':
          buttonColor = color.grey[200]
          buttonTxtColor = color.grey[500]
          break
          case 'c2':
            buttonColor = color.grey[300]
            buttonTxtColor = color.grey[500]
            break
          case 'c3':
            buttonColor = color.grey[400]
            buttonTxtColor = color.grey[200]
            break
          default:
            buttonColor = color.grey[500]
            buttonTxtColor = color.grey[200]
      }
      break
    case 'default':
    default:
      switch(lightBG)
      {
        case 'c1':
          buttonColor = color.grey[200]
          buttonTxtColor = color.grey[500]
          break
          case 'c2':
            buttonColor = color.grey[300]
            buttonTxtColor = color.grey[500]
            break
          case 'c3':
            buttonColor = color.grey[400]
            buttonTxtColor = color.grey[200]
            break
            case 'c4':
              buttonColor = color.grey[400]
              buttonTxtColor = color.grey[200]
              break
            default:
            buttonColor = color.grey[500]
            buttonTxtColor = color.grey[200]
      }
  }

  let boxShadow: string
  let buttonSize: number
  let buttonPadding: number
  let fontSize: number
  switch (size) {
    case 'sm':
      //boxShadow = `4px 4px 8px ${color.grey[300]},
      //  -8px -8px 16px ${color.grey[100]}FF;`
      buttonPadding = spacing[3]
      buttonSize = 36
      fontSize = 14
      break
    case 'lg':
      //boxShadow = `6px 6px 12px ${color.grey[300]},
      //  -12px -12px 24px ${color.grey[100]}ff;`
      buttonPadding = spacing[4]
      buttonSize = 72
      fontSize = 16
      break
    case 'md':
    default:
      //boxShadow = `6px 6px 12px ${color.grey[300]},
      //  -12px -12px 24px -2px ${color.grey[100]}ff;`
      buttonPadding = spacing[4]
      buttonSize = 56
      fontSize = 16
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>
    } else if (href) {
      return <StyledExternalLink href={href} target="_self">{text}</StyledExternalLink>
    } else {
      return text
    }
  }, [href, text, to])

  return (
    <StyledButton
      boxShadow={boxShadow}
      color={buttonColor}
      lightBG={buttonTxtColor}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
    >
      {children}
      {ButtonChild}
    </StyledButton>
  )
}

interface StyledButtonProps {
  boxShadow: string,
  color: string,
  lightBG?: string,
  disabled?: boolean,
  fontSize: number,
  padding: number,
  size: number
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background-color: ${props => props.color};
  border: 0;
  border-radius: 12px;
  box-shadow: ${props => props.boxShadow};
  color: ${props => !props.disabled ? props.lightBG : `${props.lightBG}55`};
  cursor: pointer;
  display: flex;
  font-size: ${props => props.fontSize}px;
  font-weight: 700;
  height: ${props => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${props => props.padding}px;
  padding-right: ${props => props.padding}px;
  pointer-events: ${props => !props.disabled ? undefined : 'none'};
  width: 100%;
  &:hover {
    background-color: ${props => props.theme.color.grey[100]};
  }
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button