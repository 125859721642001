import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import ParticlesBg from "react-tsparticles";

//import ScriptTag from 'react-script-tag';
// const Demo = props => (
// <ScriptTag type="text/javascript" src="./assets/particles.js" />
// )
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
