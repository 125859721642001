import React from 'react'
import styled from 'styled-components'
import favIconLogo from '../../assets/img/central-logo.png'



import Container from '../Container'

interface PageHeaderProps {
  icon: React.ReactNode
  subtitle?: string
  title?: string
  title1?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ icon, subtitle, title, title1 }) => {
  return (
    <Container size="sm">
      <StyledPageHeader>
        <StyledIcon>{<img src={favIconLogo} height='50%' />}</StyledIcon>
        {/* <StyledTitle>{title}</StyledTitle> */}
        <StyledTitle>{title1}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </StyledPageHeader>
    </Container>
  )
}

const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: ${(props) => props.theme.spacing[1]}px;
  margin: 0 auto;
`

const StyledIcon = styled.div`
  font-size: 120px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  padding-bottom: 20px;
`
//  width: 120px;

const StyledTitle = styled.h1`
  // font-family: 'Kaushan Script', sans-serif;
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  padding: 0;
`

const StyledSubtitle = styled.h3`
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default PageHeader
