import React from 'react'
import styled from 'styled-components'
import {contractAddresses} from '../../../lic/lib/constants';
import {getEthChainInfo} from "../../../utils/getEthChainInfo";
// import {GITHUB} from '../../../constants/config';

const {
    ethscanType,
    chainId
} = getEthChainInfo();

const contractAddressesTemp = contractAddresses as {[index: string]:any};

const Nav: React.FC = () => {
    return (
    <StyledNav>
            {/* <StyledLink
        href="https://quickswap.exchange/#/add/0x860bE08b546E13Cc52826728A821Aefaf0F026Ea/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
        target="_self"
      >
        Add Liquidity 
      </StyledLink>

      <StyledLink
        href="https://quickswap.exchange/#/remove/0x860bE08b546E13Cc52826728A821Aefaf0F026Ea/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
        target="_self"
      >
        Remove Liquidity 
      </StyledLink> */}
      {/* <StyledLink
        target="_blank"
        href={`https://${ethscanType}/address/${contractAddressesTemp.lic[chainId]}#code`}
      >
        LIC Contract
      </StyledLink> */}
      {/* <StyledLink
        target="_blank"
        href={`https://${ethscanType}/address/${contractAddressesTemp.stakeLic[chainId]}#code`}
      >
        Pool Contract
      </StyledLink> */}
      {/*<StyledLink*/}
      {/*  target="_blank"*/}
      {/*  href={`https://uniswap.info/pair/${contractAddressesTemp.lic[chainId]}`}*/}
      {/*>*/}
      {/*  Uniswap LIC-ETH*/}
      {/*</StyledLink>*/}
      {/* <StyledLink target="_blank" href="https://discord.gg/zTdmUkb">
        Discord
      </StyledLink>
        <StyledLink target="_blank" href="https://t.me/joinchat/KABj-Bz6CVzyi23HK2rjzA">
        Telegram
      </StyledLink> */}
      {/*<StyledLink target="_blank" href="https://github.com/lic">*/}
      {/*<StyledLink target="_blank" href={GITHUB}>*/}
      {/*  Github*/}
      {/*</StyledLink>*/}
      {/* <StyledLink target="_blank" href="https://twitter.com/lic">
      Twitter
      </StyledLink> */}
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[200]};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[600]};
  }
`

export default Nav
