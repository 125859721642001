import React, { useMemo, useEffect } from 'react'
//import styled from 'styled-components'
import styled, { keyframes } from 'styled-components'

import { useParams } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'

import Button from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'

import useYam from '../../hooks/useYam'
import useFarm from '../../hooks/useFarm'
import useRedeem from '../../hooks/useRedeem'
import { getContract } from '../../utils/erc20'
import { getStakeLicContract } from '../../lic/utils'
import GetLockTime from '../../hooks/useUserTime'
import GetLockDur from '../../hooks/usePoolLockDur'

import Timer from './components/Timer'
import Harvest from './components/Harvest'
import Stake from './components/Stake'
import { now } from 'moment'

import favIconLogo from '../../assets/img/central-logo.png'


const Farm: React.FC = () => {
  const { farmId } = useParams()
  const {
    pid,
    lpToken,
    lpTokenAddress,
    tokenAddress,
    earnToken,
    name,
    icon,
  } = useFarm(farmId) || {
    pid: 0,
    lpToken: '',
    lpTokenAddress: '',
    tokenAddress: '',
    earnToken: '',
    name: '',
    icon: '',
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const yam = useYam()
  const { ethereum } = useWallet()
  var onUserTime  = GetLockTime(pid)
  const onLockTime  = GetLockDur(pid)
  var onUserTime1 = (onUserTime.toNumber()*1000+(onLockTime.toNumber()*1000))
  var currTime = now();
//  onUserTime7889229000 Now1641641764384Lock Dur 7889229

  console.log("onUserTime:::=>" + onUserTime);
  if(onUserTime.toNumber() == 0) onUserTime1 = currTime + Number(2629743*3)*1000;
  var myDate1 = new Date( onUserTime1)
  var myDate= myDate1.toUTCString()
  var durresult = false;
  if(onUserTime1 < currTime)
  {
    durresult = true
  }
  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, lpTokenAddress)
  }, [ethereum, lpTokenAddress])

  const { onRedeem } = useRedeem(getStakeLicContract(yam))

  const lpTokenName = useMemo(() => {
    return lpToken.toUpperCase()
  }, [lpToken])

  const earnTokenName = useMemo(() => {
    return earnToken
  }, [earnToken])

  return (
    <>
      <PageHeader
        icon={icon}
        subtitle={`Deposit ${lpTokenName}  Tokens and earn ${earnTokenName}`}
        title={name}
      />
      <StyledFarm>
        
        <StyledCardWrapper>
            <Timer pid={pid} />
          </StyledCardWrapper>
          <Spacer size="lg" />
          <StyledCardsWrapper>
          <StyledCardWrapper>
            <Harvest pid={pid} />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper style={{display: 'flex', flexDirection: 'row'}}>
            <Stake
              lpContract={lpContract}
              pid={pid}
              tokenName={lpToken.toUpperCase()}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <StyledInfo>
          {'Unstake will be enabled after ' + myDate + ''}
          <Spacer size="lg" />
          {farmId=='LIC' ? '⭐️ Your reward will be automatically claimed when you withdraw or deposit!' : '⭐️ Your reward will be automatically claimed when you withdraw or deposit!'}
          
        </StyledInfo>
        <Spacer size="lg" />
      </StyledFarm>
    </>
  )
}

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[300]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default Farm
