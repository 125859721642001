import { useCallback, useEffect, useState } from 'react'
import { provider } from 'web3-core'

import BigNumber from 'bignumber.js'
import { useWallet } from 'use-wallet'

import { getEarned, getStakeLicContract, getTimer } from '../lic/utils'
import useYam from './useYam'
import useBlock from './useBlock'

const useHalvetime = (pid: number) => {
  const [balance, setBalance] = useState(new Date())
  const {
    account,
    ethereum,
  }: { account: string; ethereum: provider } = useWallet()
  const yam = useYam()
  const stakeLicContract = getStakeLicContract(yam)
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    var balance = await getTimer(stakeLicContract, pid)
    
    setBalance(new Date(balance.toString()))
  }, [stakeLicContract, yam])

  useEffect(() => {
    if (stakeLicContract && yam) {
      fetchBalance()
    }
  }, [account, block, stakeLicContract, setBalance, yam])

  return balance
}

export default useHalvetime
