/* export const getEthChainInfo = () => {
    let chainId: number = 42;
    let rpcUrl: string = 'https://kovan.infura.io/';
    let ethscanType: string = 'kovan.';
    const href = window.location.href;
    // if (/\/\/[lic|lic|]+.aelf.io|\/\/lic.cool/.test(href)) {
        //chainId = 1;
        //rpcUrl = 'https://mainnet.eth.aragon.network/';
        chainId = 42;
        rpcUrl = 'https://kovan.infura.io/';
        
        ethscanType = 'kovan.';
    // }
    return {
        chainId,
        rpcUrl,
        ethscanType
    }
};
 */

export const getEthChainInfo = () => {
    var chainIdcheck = 137;
    // var chainIdcheck = 80001;
    var cId, rUrl, eType;
    if(chainIdcheck == 80001)
    {
        cId=80001;
        rUrl="https://matic-mumbai.chainstacklabs.com/";
        eType="mumbai.polygonscan.com";
    }
    if(chainIdcheck == 137)
    {
        cId=137;
        rUrl="https://polygon-rpc.com/";
        eType="polygonscan.com";
    }
    let chainId: number = cId;
    let rpcUrl: string = rUrl;
    let ethscanType: string = eType;
    const href = window.location.href;
    // if (/\/\/[lic|lic|]+.aelf.io|\/\/lic.cool/.test(href)) {
        //chainId = 1;
        //rpcUrl = 'https://mainnet.eth.aragon.network/';
        chainId = cId;
        rpcUrl = rUrl;
        
        ethscanType = eType;
    // }
    return {
        chainId,
        rpcUrl,
        ethscanType
    }
};
