import { ethers } from 'ethers'
import { Contract } from 'web3-eth-contract'
import ERC20Abi from './lib/abi/erc20.json'
import $ from 'jquery'
import moment from 'moment'
import BigNumber from 'bignumber.js'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const GAS_LIMIT = {
  STAKING: {
    DEFAULT: 200000,
    SNX: 850000,
  },
}
var testtime;
export const getStakeLicAddress = (lic) => {
  return lic && lic.stakeLicAddress
}
export const getLicAddress = (lic) => {
  return lic && lic.licAddress
}
export const getWethContract = (lic) => {
  return lic && lic.contracts && lic.contracts.weth
}

export const getStakeLicContract = (lic) => {
  return lic && lic.contracts && lic.contracts.stakeLic
}
export const getLicContract = (lic) => {
  return lic && lic.contracts && lic.contracts.lic
}

export const getFarms = (lic) => {
  return lic
    ? lic.contracts.pools.map(
        ({
          pid,
          name,
          symbol,
          icon,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          lpAddress,
          lpContract,
          pool,
          periodFinish,
          lockdur,
        }) => ({
          pid,
          id: symbol,
          name,
          lpToken: symbol,
          lpTokenAddress: lpAddress,
          lpContract,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          periodFinish: periodFinish,
          // earnToken: 'lic',
          earnToken: tokenSymbol,
          earnTokenAddress: lic.contracts.lic.options.address,
          icon,
          pool,
          lockdur: lockdur,
        }),
      )
    : []
}

export const getTokenFarms = (lic) => {
  return lic
    ? lic.contracts.poolsToken.map(
        ({
          pid,
          name,
          symbol,
          icon,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          lpAddress,
          lpContract,
          pool,
          lockdur,
        }) => ({
          pid,
          id: symbol,
          name,
          lpToken: symbol,
          lpTokenAddress: lpAddress,
          lpContract,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          // earnToken: 'lic',
          earnToken: tokenSymbol,
          earnTokenAddress: lic.contracts.lic.options.address,
          icon,
          pool,
          lockdur,
        }),
      )
    : []
}

export const getPoolWeight = async (stakeLicContract, pid) => {
  try {
    const { allocPoint } = await stakeLicContract.methods.poolInfo(pid).call()
    const totalAllocPoint = await stakeLicContract.methods
      .totalAllocPoint()
      .call()
    return new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))
  } catch {
    return new BigNumber(0)
  }
}

export const getEarned = async (stakeLicContract, pid, account) => {
  //stakeLicContract.methods.getMultiplier(pid).call()
  //console.log(pid)
  return stakeLicContract.methods.pendingLic(pid, account).call()
}

export const getTimer = async (stakeLicContract, pid) => {
 //try {
    
    // const { starttime } = await stakeLicContract.methods
    //   .poolInfo(pid)
    //   .call()
    //  const  starttime  = await stakeLicContract.methods
    // .poolCycleFinish(pid)
    // .call()    
    //var RemTime = Math.floor(Date.now()/1000) + starttime
    // var RemTime =  (starttime * 1000) + (7776000 * 1000)
    // var newDate = new Intl.DateTimeFormat("en-GB", {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'
    // }).format(RemTime)
    // console.log("AAAAAAAAAAA" + new BigNumber(newDate))
    // $('#RemTime').text("newDate")
    //   return new BigNumber(newDate) 
    var  starttime  = await stakeLicContract.methods
    .poolCycleFinish(pid)
    .call()
    
    if(starttime != undefined) 
    {
      testtime = starttime;
    }
    else
    {
      starttime = testtime;
    }
    var t = new Date(1970, 0, 1); // Epoch
    //var newDate = t.setSeconds(parseInt(newDate));
    //var newDate = moment(testtime).format('MM/DD/YYYY hh:MM');
    var newDate = moment(testtime, "YYYYMMDD").fromNow();
    console.log("Test Time: "+ testtime);
    if(testtime==0)
    {
      testtime = new Date().getTime()+ Number(2629743*3)*1000;
      var myDate = new Date( testtime);
    } 
    else
    {
      var myDate = new Date( testtime*1000);
    }
//document.write(myDate.toGMTString()+"<hr>"+myDate.toLocaleString());
//    newDate = new Intl.DateTimeFormat("en-GB", {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'
  //    }).format(testtime)
    console.log("Time first " + myDate.toGMTString())
    
    $('#RemTime').text(myDate.toGMTString())

    return new Date(newDate) 
   // return starttime
 /* } catch {
    console.log("Time Second " + testtime)
    return testtime;
  }*/
 // return stakeLicContract.methods.poolCycleFinish(pid).call()
}

export const getTotalLPWethValue = async (
  stakeLicContract,
  wethContract,
  lpContract,
  tokenContract,
  pid,
) => {
  // Get balance of the token address
  const tokenAmountWholeLP = await tokenContract.methods
    .balanceOf(lpContract.options.address)
    .call()
  const tokenDecimals = await tokenContract.methods.decimals().call()
  // Get the share of lpContract that stakeLicContract owns
  const balance = await lpContract.methods
    .balanceOf(stakeLicContract.options.address)
    .call()
  // Convert that into the portion of total lpContract = p1
  const totalSupply = await lpContract.methods.totalSupply().call()
  // Get total weth value for the lpContract = w1
  const lpContractWeth = await wethContract.methods
    .balanceOf(lpContract.options.address)
    .call()
  // Return p1 * w1 * 2
  const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
  const lpWethWorth = new BigNumber(lpContractWeth)
  const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))
  // Calculate
  const tokenAmount = new BigNumber(tokenAmountWholeLP)
    .times(portionLp)
    .div(new BigNumber(10).pow(tokenDecimals))

  const wethAmount = new BigNumber(lpContractWeth)
    .times(portionLp)
    .div(new BigNumber(10).pow(18))
  return {
    tokenAmount,
    wethAmount,
    totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(18)),
    tokenPriceInWeth: wethAmount.div(tokenAmount),
    poolWeight: await getPoolWeight(stakeLicContract, pid),
  }
}

export const approve = async (lpContract, stakeLicContract, account) => {
  return lpContract.methods
    .approve(stakeLicContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const getLicSupply = async (lic) => {
  return new BigNumber(await lic.contracts.lic.methods.totalSupply().call())
}

//

export const getPoolStartTime = async (stakeLicContract) => {
  return await stakeLicContract.methods.starttime().call()
}

export const stake = async (stakeLicContract, pid, amount, account) => {
  return stakeLicContract.methods
    .deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const unstake = async (stakeLicContract, pid, amount, account) => {
  return stakeLicContract.methods
    .withdraw(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}
export const harvest = async (stakeLicContract, pid, account) => {
  return stakeLicContract.methods
    //.deposit(pid, '0')
    .getReward(pid,2)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const getStaked = async (stakeLicContract, pid, account) => {
  try {
    const { amount  } = await stakeLicContract.methods
      .userInfo(pid, account)
      .call()
    return new BigNumber(amount)
  } catch {
    return new BigNumber(0)
  }
}

export const getLockTime = async (stakeLicContract, pid, account) => {
  try {
    const { userStartBlock } = await stakeLicContract.methods
      .userInfo(pid, account)
      .call()
      var myDate = new Date( userStartBlock*1000);
          //console.log("Time first " + myDate.toGMTString())
          
          $('#UserStTime').text(myDate.toGMTString());
    return new BigNumber(userStartBlock)
  } catch {
    return new BigNumber(0)
  }
}

export const getLockDur = async (stakeLicContract, pid) => {
  try {
    const { lockdur } = await stakeLicContract.methods
      .poolInfo(pid)
      .call()
      var myDate = lockdur;
          //console.log("Time first " + myDate.toGMTString())
          console.log('GetLockDuronUserTime' + lockdur)
          //$('#lockdurTime').text(myDate);
    return lockdur
  } catch(e) {
    console.log('AGetLockDuronUserTime' + e)

    return 0
  }
}

export const getrewardstatus = async (stakeLicContract, pid, account) => {
  try {
    const { rewardlock } = await stakeLicContract.methods
      .poolInfo(pid)
      .call()
      
          //console.log("Time first " + myDate.toGMTString())
          
          //$('#lockdurTime').text(myDate);
    return rewardlock
  } catch {
    return 0
  }
}

export const getDepositstatus = async (stakeLicContract, pid, account) => {
  try {
    const { mulDeposit } = await stakeLicContract.methods
      .poolInfo(pid)
      .call()
      const { userStartBlock } = await stakeLicContract.methods
      .userInfo(pid, account)
      .call()
      
      if(userStartBlock >  0 && mulDeposit == true)
      {
        return 1
      }
      else if(userStartBlock ==  0 && mulDeposit == true)
      {
        return 1
      }
      else if(userStartBlock ==  0 && mulDeposit == false)
      {
        return 1
      }
      else
      {
        return 0
      }
      //var myDate = mulDeposit;
          //console.log("Time first " + myDate.toGMTString())
          
          //$('#lockdurTime').text(myDate);
  } catch {
    return 0
  }
}

export const redeem = async (stakeLicContract, account) => {
  let now = new Date().getTime() / 1000
  if (now >= 1597172400) {
    return stakeLicContract.methods
      .exit()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
  } else {
    alert('pool not active')
  }
}

export const getPoolContracts = async (yam) => {
  const pools = Object.keys(yam.contracts)
    .filter((c) => c.indexOf('_pool') !== -1)
    .reduce((acc, cur) => {
      const newAcc = { ...acc }
      newAcc[cur] = yam.contracts[cur]
      return newAcc
    }, {})
  return pools
}
export const getPoolTokenContracts = async (yam) => {
  const poolsToken = Object.keys(yam.contracts)
    .filter((c) => c.indexOf('_pool') !== -1)
    .reduce((acc, cur) => {
      const newAcc = { ...acc }
      newAcc[cur] = yam.contracts[cur]
      return newAcc
    }, {})
  return poolsToken
}

// export const getStaked = async (yam, pool, account) => {
//   return yam.toBigN(await pool.methods.balanceOf(account).call())
// }

export const getCurrentPrice = async (yam) => {
  // FORBROCK: get current YAM price
  return yam.toBigN(await yam.contracts.rebaser.methods.getCurrentTWAP().call())
}

export const getTargetPrice = async (yam) => {
  return yam.toBigN(1).toFixed(2)
}

export const getCirculatingSupply = async (yam) => {
  let now = await yam.web3.eth.getBlock('latest')
  let scalingFactor = yam.toBigN(
    await yam.contracts.yam.methods.yamsScalingFactor().call(),
  )
  let starttime = yam
    .toBigN(await yam.contracts.eth_pool.methods.starttime().call())
    .toNumber()
  let timePassed = now['timestamp'] - starttime
  if (timePassed < 0) {
    return 0
  }
  let yamsDistributed = yam.toBigN((8 * timePassed * 250000) / 625000) //yams from first 8 pools
  let starttimePool2 = yam
    .toBigN(await yam.contracts.ycrv_pool.methods.starttime().call())
    .toNumber()
  timePassed = now['timestamp'] - starttime
  let pool2Yams = yam.toBigN((timePassed * 1500000) / 625000) // yams from second pool. note: just accounts for first week
  let circulating = pool2Yams
    .plus(yamsDistributed)
    .times(scalingFactor)
    .div(10 ** 36)
    .toFixed(2)
  return circulating
}

export const getNextRebaseTimestamp = async (yam) => {
  try {
    let now = await yam.web3.eth.getBlock('latest').then((res) => res.timestamp)
    let interval = 43200 // 12 hours
    let offset = 28800 // 8am/8pm utc
    let secondsToRebase = 0
    if (await yam.contracts.rebaser.methods.rebasingActive().call()) {
      if (now % interval > offset) {
        secondsToRebase = interval - (now % interval) + offset
      } else {
        secondsToRebase = offset - (now % interval)
      }
    } else {
      let twap_init = yam
        .toBigN(await yam.contracts.rebaser.methods.timeOfTWAPInit().call())
        .toNumber()
      if (twap_init > 0) {
        let delay = yam
          .toBigN(await yam.contracts.rebaser.methods.rebaseDelay().call())
          .toNumber()
        let endTime = twap_init + delay
        if (endTime % interval > offset) {
          secondsToRebase = interval - (endTime % interval) + offset
        } else {
          secondsToRebase = offset - (endTime % interval)
        }
        return endTime + secondsToRebase
      } else {
        return now + 13 * 60 * 60 // just know that its greater than 12 hours away
      }
    }
    return secondsToRebase
  } catch (e) {
    console.log(e)
  }
}

export const getTotalSupply = async (yam) => {
  return await yam.contracts.yam.methods.totalSupply().call()
}

export const getStats = async (yam) => {
  const curPrice = await getCurrentPrice(yam)
  const circSupply = await getCirculatingSupply(yam)
  const nextRebase = await getNextRebaseTimestamp(yam)
  const targetPrice = await getTargetPrice(yam)
  const totalSupply = await getTotalSupply(yam)
  return {
    circSupply,
    curPrice,
    nextRebase,
    targetPrice,
    totalSupply,
  }
}

export const vote = async (yam, account) => {
  return yam.contracts.gov.methods.castVote(0, true).send({ from: account })
}

export const delegate = async (yam, account) => {
  return yam.contracts.yam.methods
    .delegate('0x683A78bA1f6b25E29fbBC9Cd1BFA29A51520De84')
    .send({ from: account })
}

export const didDelegate = async (yam, account) => {
  return (
    (await yam.contracts.yam.methods.delegates(account).call()) ===
    '0x683A78bA1f6b25E29fbBC9Cd1BFA29A51520De84'
  )
}

export const getVotes = async (yam) => {
  const votesRaw = new BigNumber(
    await yam.contracts.yam.methods
      .getCurrentVotes('0x683A78bA1f6b25E29fbBC9Cd1BFA29A51520De84')
      .call(),
  ).div(10 ** 24)
  return votesRaw
}

export const getScalingFactor = async (yam) => {
  return new BigNumber(
    await yam.contracts.yam.methods.yamsScalingFactor().call(),
  )
}

export const getDelegatedBalance = async (yam, account) => {
  return new BigNumber(
    await yam.contracts.yam.methods.balanceOfUnderlying(account).call(),
  ).div(10 ** 24)
}

export const migrate = async (yam, account) => {
  return yam.contracts.yamV2migration.methods.migrate().send({ from: account })
}

export const getMigrationEndTime = async (yam) => {
  return yam
    .toBigN(await yam.contracts.yamV2migration.methods.startTime().call())
    .plus(yam.toBigN(86400 * 3))
    .toNumber()
}
