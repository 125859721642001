import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useWallet } from 'use-wallet'

import useModal from '../../../hooks/useModal'

import Button from '../../Button'
import WalletProviderModal from '../../WalletProviderModal'

import AccountModal from './AccountModal'

interface AccountButtonProps {}

const AccountButton: React.FC<AccountButtonProps> = (props) => {

  const [onPresentAccountModal] = useModal(<AccountModal />)
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />, 'provider')
  
  const { account } = useWallet()
  

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal()
  }, [onPresentWalletProviderModal])

  return (
    <StyledAccountButton>
      {!account ? (
        <Button
          onClick={handleUnlockClick}
          size="sm"
          text="Unlock Wallet"
          lightBG="c1"
        />
      ) : (
        
        <Button
          onClick={onPresentAccountModal}
          size="sm"
          text={account.substring(0,4) + '...' + account.substring(account.length-5,account.length)}
          lightBG="c1"
        />
      )}
    </StyledAccountButton>
  )
}
//var shortaccount=useWallet()
//shortaccount=shortaccount.substring(1,4)
const StyledAccountButton = styled.div``

export default AccountButton