import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'


import { Contract } from 'web3-eth-contract'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import { AddIcon, RemoveIcon } from '../../../components/icons'
import IconButton from '../../../components/IconButton'
import Label from '../../../components/Label'
import Value from '../../../components/Value'

import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useStake from '../../../hooks/useStake'
import GetLockTime from '../../../hooks/useUserTime'
import GetLockDur from '../../../hooks/usePoolLockDur'
import useStakedBalance from '../../../hooks/useStakedBalance'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useUnstake from '../../../hooks/useUnstake'
import DepositStatus from '../../../hooks/usePoolDepositStatus'

import { getBalanceNumber, getFullDisplayBalance, getDisplayBalance } from '../../../utils/formatBalance'

import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'
import { now } from 'moment'

interface StakeProps {
  lpContract: Contract
  pid: number
  tokenName: string
}

const Stake: React.FC<StakeProps> = ({ lpContract, pid, tokenName }) => {
  const [requestedApproval, setRequestedApproval] = useState(false)

  const allowance = useAllowance(lpContract)
  const { onApprove } = useApprove(lpContract)

  const tokenBalance = useTokenBalance(lpContract.options.address)
  const stakedBalance = useStakedBalance(pid)
  const { onStake } = useStake(pid)
  const { onUnstake } = useUnstake(pid)
  var onUserTime  = GetLockTime(pid)
  const onLockTime  = GetLockDur(pid)
  var depstatus = DepositStatus(pid)
  
  var onUserTime1 = (onUserTime.toNumber()*1000+(onLockTime.toNumber()*1000))
  var currTime = now()
  var durresult = false;
  if(onUserTime1 < currTime)
  {
    durresult = true
    if(!stakedBalance.eq(new BigNumber(0))) depstatus = new BigNumber(0)
  }
  
  console.log('onUserTime' + onUserTime1 + ' Now' + currTime + 'Lock Dur ' + onLockTime)
  //$('#LockDur').text(onUserTime1)
  //var usertime=event.currentTarget.getAttribute('UserStTime').value;
  const [onPresentDeposit] = useModal(
    <DepositModal
      max={tokenBalance}
      onConfirm={onStake}
      tokenName={tokenName}
    />,
  )

  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={stakedBalance}
      onConfirm={onUnstake}
      tokenName={tokenName}
    />,
  )

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
          <StyledCardHeader>
            <CardIcon>⏳</CardIcon>
            <Value value={getDisplayBalance(stakedBalance)} />
            <Label text={`${tokenName} Tokens Staked`}/>
          </StyledCardHeader>
          <StyledCardActions>
            {!allowance.toNumber() ? (
              <Button
                disabled={requestedApproval}
                onClick={handleApprove}
                text={`Approve ${tokenName}`}
                lightBG="c3"
              />
            ) : (
              <>{
                
                durresult ? (<Button
                disabled={stakedBalance.eq(new BigNumber(0))}
                // disabled={stakedBalance.eq(new BigNumber(0))}
                text="Withdraw"
                onClick={onPresentWithdraw}
                lightBG="c3"
              />) : (<Button
                disabled={true}
                text="Withdraw"
                onClick={onPresentWithdraw}
                lightBG="c3"
              />)}
              {
              /* {Date(onUserTime) >= Date() ? 'AAA' : 'BBB'} */}
                {/* <Button
                  disabled={stakedBalance.eq(new BigNumber(0))}
                  text="Withdraw"
                  onClick={onPresentWithdraw}
                  lightBG="c3"
                /> */}
                <StyledActionSpacer />
                <IconButton onClick={onPresentDeposit} disabled={depstatus.eq(new BigNumber(0))}>
                  <AddIcon />
                </IconButton>
              </>
            )}
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  
`

export default Stake
