import React, { useState } from 'react'
import styled from 'styled-components'

import { Contract } from 'web3-eth-contract'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardIcon from '../../../components/CardIcon'
import Label from '../../../components/Label'
import Value from '../../../components/Value'

import useHalvetime from '../../../hooks/useHalvetime'

import useReward from '../../../hooks/useReward'

import { getTimer, getStakeLicContract } from '../../../lic/utils'

import BigNumber from 'bignumber.js'

import useYam from '../../../hooks/useYam'


import {
  getDisplayBalance,
  getBalanceNumber,
} from '../../../utils/formatBalance'

interface HarvestProps {
  pid: number
}

const Timer: React.FC<HarvestProps> = ({ pid }) => {
  const yam = useYam()
  const stakeLicContract = getStakeLicContract(yam)
  var TimeRem = useHalvetime(pid);
  //TimeRem = getTimer(stakeLicContract, pid)

  console.log("Time Rem: " + TimeRem + " PID: " + pid);
  var t = new Date(1970, 0, 1); // Epoch
  var newtime=new Date().getTime();
  if(new Date(TimeRem)) newtime = t.setSeconds(parseInt(TimeRem.toString()));

  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useReward(pid)

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
          <StyledCardHeader>
             {/* <Value value={newtime.toString()} /> */}
            
          </StyledCardHeader>
            <Label text="Reward will be stopped after" /><span id="RemTime"></span>
          
        </StyledCardContentInner>
      </CardContent>
    </Card>
     
  )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export default Timer
