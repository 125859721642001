import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Nav: React.FC = () => {
  return (
    <StyledNav>
       <StyledAbsoluteLink
        href="https://liccoin.io/index.html"
        target="_self"
      >Home 
      </StyledAbsoluteLink>
      {/* <StyledLink exact activeClassName="active" to="/">
        Home
      </StyledLink> */}
      <StyledLink exact activeClassName="active" to="/">
        Stake
      </StyledLink>

      {/* <StyledLink exact activeClassName="active" to="/staking">
        Stake
      </StyledLink> */}
{/*       <StyledLink exact activeClassName="active" to="#">
        Swap
      </StyledLink>
      <StyledLink exact activeClassName="active" to="#">
        Vault
      </StyledLink>
      <StyledLink exact activeClassName="active" to="#">
        Vote
      </StyledLink>
 */}
       {/* <StyledAbsoluteLink
        href="https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0x860bE08b546E13Cc52826728A821Aefaf0F026Ea"
        target="_self"
      >
        Swap 
      </StyledAbsoluteLink> */}
 
       {/*<StyledAbsoluteLink*/}
      {/*  href="https://medium.com/lic/the-lic-project-c4049ea9941e"*/}
      {/*  target="_blank"*/}
      {/*>*/}
      {/*  About*/}
      {/*</StyledAbsoluteLink>*/}
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.color.grey[200]};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[600]};
  }
  &.active {
    color: ${(props) => props.theme.color.primary.main};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

const StyledAbsoluteLink = styled.a`
  color: ${(props) => props.theme.color.grey[200]};
  font-weight: 700;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[600]};
  }
  &.active {
    color: ${(props) => props.theme.color.primary.main};
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

export default Nav
