import React, { useCallback, useEffect, useState } from 'react'
import { HashRouter as Router, Link, Route, Switch } from 'react-router-dom'
//import { browserHistory } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { UseWalletProvider } from 'use-wallet'
import styled from 'styled-components'
//import RedirectOnServer from './components/RedirectOnServer';

import DisclaimerModal from './components/DisclaimerModal'
import MobileMenu from './components/MobileMenu'
import TopBar from './components/TopBar'

import FarmsProvider from './contexts/Farms'
import ModalsProvider from './contexts/Modals'
import YamProvider from './contexts/YamProvider'
import TransactionProvider from './contexts/Transactions'

import useModal from './hooks/useModal'

import FAQ from './views/FAQ'
import Farms from './views/Farms'
import Home from './views/Home'
import Stake from './views/Stake'

import bgimg from './assets/img/BGImg.jpeg'

import { getEthChainInfo } from './utils/getEthChainInfo'

import theme from './theme'
import ParticlesBg from "react-tsparticles";


const App: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])

  return (
    
    <Providers>
            

      <Router>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
      >

<ParticlesBg
        id="tsparticles"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 800
              }
            },
             background: {
             size: {
               value: "cover",
             },
            },
            backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        width: '100vw',
        height: '100vh',
        minHeight: '100%',
            color: {
              value: "#ffffff"
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.4,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: true,
                speed: 2,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable_auto: true,
              distance: 100,
              color: "#fff",
              opacity: 1,
              width: 1,
              condensed_mode: {
                enable: false,
                rotateX: 600,
                rotateY: 600
              }
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: false
              },
              onclick: {
                enable: false
              },
              resize: true
            }
          },
          retina_detect: true
        }}
      //   options={{
      //     background: {
      //       color: {
      //         value: "#201940",
      //       },

      //       size: "cover",
      //       width: "100%",
      //       height: "100%",
      //       position: "fixed",
      // },
      // fullScreen: {
      //   enable: true,
      //   zIndex: 1
      // },
      //     fpsLimit: 10,
      //     interactivity: {
      //       detectsOn: "canvas",
      //       events: {
      //         onClick: {
      //           enable: true,
      //           mode: "push",
      //         },
      //         onHover: {
      //           enable: true,
      //           mode: "repulse",
      //         },
      //         resize: true,
      //       },
      //       modes: {
      //         bubble: {
      //           distance: 400,
      //           duration: 2,
      //           opacity: 0.8,
      //           size: 40,
                
      //         },
      //         push: {
      //           quantity: 4,
      //         },
      //         repulse: {
      //           distance: 200,
      //           duration: 0.4,
      //         },
      //       },
      //     },
      //     particles: {
      //       color: {
      //         value: "#000000",
      //       },
      //       links: {
      //         color: "#000000",
      //         distance: 150,
      //         enable: true,
      //         opacity: 0.5,
      //         width: 1,
      //       },
      //       collisions: {
      //         enable: true,
      //       },
      //       move: {
      //         direction: "none",
      //         enable: true,
      //         outMode: "bounce",
      //         random: false,
      //         speed: 6,
      //         straight: false,
      //       },
      //       number: {
      //         density: {
      //           enable: true,
      //           value_area: 800,
      //         },
      //         value: 80,
      //       },
      //       opacity: {
      //         value: 0.5,
      //       },
      //       shape: {
      //         type: "circle",
      //       },
      //       size: {
      //         random: true,
      //         value: 5,
      //       },
      //     },
      //     detectRetina: true,
      //   }}
      />
<div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        >



        <TopBar onPresentMobileMenu={handlePresentMobileMenu} />
        <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/farms">
            <Farms />
          </Route>
          {/* <Route path="/staking">
            <Stake />
          </Route> */}
          <Route path="/faq">
            <FAQ />
          </Route>
        </Switch>
        </div>

</div>
      </Router>
      <Disclaimer />
    </Providers>
  )
}

// https://infura.io/docs/gettingStarted/chooseaNetwork
// https://www.anyblockanalytics.com/news/overview-ethereum-blockchain-networks/
const Providers: React.FC = ({ children }) => {

  const {
    chainId,
    rpcUrl
  } = getEthChainInfo();

  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={chainId}
        connectors={{
          walletconnect: { rpcUrl },
        }}
      >
        <YamProvider>
          <TransactionProvider>
            <FarmsProvider>
              <ModalsProvider>{children}</ModalsProvider>
            </FarmsProvider>
          </TransactionProvider>
        </YamProvider>
      </UseWalletProvider>
    </ThemeProvider>
  )
}

const Disclaimer: React.FC = () => {
  const markSeen = useCallback(() => {
    localStorage.setItem('disclaimer', 'seen')
  }, [])

  const [onPresentDisclaimerModal] = useModal(
    <DisclaimerModal onConfirm={markSeen} />,
  )

  useEffect(() => {
    const seenDisclaimer = true // localStorage.getItem('disclaimer')
    if (!seenDisclaimer) {
      onPresentDisclaimerModal()
    }
  }, [])

  return (<div/>

 
)

}
const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[400]};
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`

export default App
