export const white = '#FFF'
export const black = '#000'

export const green = {
  500: '##00d1810',
}

export const red = {
  100: '#FFFDFE',
  200: '#ffc2a8',
  500: '#d16c00',
}

export const grey = {
  100: '#eff5f1',
  200: '#87CEFA',
  300: '#cfe2d6',
  400: '#1c81bf',
  500: '#201940',
  600: '#eff5f1',
  800: '#112c16',
}
/*
export const grey = {
  100: '#f7f4f2',
  200: '#f0e9e7',
  300: '#e2d6cf',
  400: '#aa9585',
  500: '#805e49',
  600: '#5b3926',
  800: '#2d1e12',
}
*/